import React, { lazy, Suspense, useState } from 'react';
import 'react-day-picker/dist/style.css';
import './App.css';
import { DayPicker } from 'react-day-picker';
import { Container, Row, Col } from 'react-grid-system';

const TideChart = lazy(() => import('./components/TideEChart'));
const WeatherChart = lazy(() => import('./components/WeatherEChart'));
const today = new Date();

function getNow() {
  var now = new Date()
  now.setMinutes(Math.round(now.getMinutes()/10)*10)
  now.setSeconds(0)
  return now
} 

function App() {
  const [date, setDate] = useState(today);
  const [datenow, setNow] = useState(getNow())

  function handledDayClick(day) {
    setDate(day)
    setNow(getNow())
  }

  const footer = <span id='today-btn' onClick={e => setDate(new Date())}>today</span>
  
  return (
    <Suspense fallback={<span>Loading ...</span>}>
      <Container className="app">
      <h1>Scheveningen Beach</h1>

        <Row justify='center' align='center'>
            <Col lg={3}>
              <DayPicker key="dayPicker"
                //numberOfMonths={2} pagedNavigation 
                showOutsideDays
                fixedWeeks
                fromMonth={new Date(2022,0)}
                toMonth={new Date(2024,11)}
                onDayClick={handledDayClick}
                selected={date}
                footer={footer}
              />
            </Col>
        </Row>
        
        <Row>
            <Col lg={6}>
                <Suspense fallback={<div>Loading Component</div>}>
                  <TideChart date={date}  datenow={datenow}/>
                </Suspense>
            </Col>
            <Col lg={6}>
                <Suspense fallback={<div>Loading Component</div>}>
                  <WeatherChart date={date} />
                </Suspense>
            </Col>
        </Row>
      </Container>
    </Suspense>
  );
}
export default App;

